<template>
	<div>
		<div v-if="activeIndex == 'platform'">

			<div class="main">

				<div class="text-center bottom-text">
					Copyright@smheee.com, All Rights Reserved. Email : shengming_tech@163.com Tel:
					020-31802914
				</div>
			</div>
		</div>

		<router-view v-else />
	</div>
</template>

<script>
	import store from "../../../store/index";
	import configApi from "../../../config/configApi";
	export default {
		components: {},
		data() {
			return {
				activeIndex: "",
				chatsData: null,
				timeFrome: {
					type: "0",
				},
			};
		},
		watch: {
			"$route.name": {
				handler(val) {
					this.activeIndex = val;
				},
				immediate: true,
			},
		},
		created() {},
		methods: {},
	};
</script>

<style lang='scss'>
	@import "../../../style/home.scss";

	.indexTopBox {
		width: 100%;
		background-color: #fff;

		.indexTop {
			width: 1420px;
			height: 60px;
			margin: 0 auto;
		}
	}

	.home {
		.hover {
			cursor: pointer;
		}

		.el-avatar>img {
			width: 100%;
		}

		background-color: #f5f5f5;
		height: 100vh;

		.main {
			width: 1420px;
			margin: 0 auto;
		}
	}

	.el-popover {
		.userInfo {
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #f5f5f5;
		}

		.textInfo {
			height: 30px;
			line-height: 30px;
		}

		.info {
			color: #9b9b9b;
		}
	}
</style>
